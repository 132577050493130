'use client';
import { Category } from '@/types';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { CaretDownIcon } from '@radix-ui/react-icons';
import { ReactNode, useEffect, useState } from 'react';
import { Submenu } from '@/components/Navigation/Submenu';
import './style.scss';
import Dropdown from '@/components/Dropdown';

interface Props {
  categories: Array<Category>;
}

export function Navbar({ categories }: Props) {

  const [data, setData] = useState<Array<Category[]>>([]);

  useEffect(() => {
    const tamanhoSubArray = 2;

    let aDevided: Array<Category>[] = [];

    for (let i = 0; i < categories.length; i += tamanhoSubArray) {
      const subArray = categories.slice(i, i + tamanhoSubArray);
      aDevided.push(subArray);
    }

    setData(aDevided);

  }, []);

  return (
    <>
      <Dropdown categories={categories}/>
      {
        data.slice(0, 3).map((category, i: number) => (
            <NavigationMenu.Root key={i} className='NavigationMenuRoot '>
              <NavigationMenu.List className='NavigationMenuList'>
                {category.map((item, i: number) => (
                  <Item key={i}>
                    <Trigger>

                      <i className={`mr-1 mdi text-[20px] ${item.Icon}`} />
                      {item.Name}

                    </Trigger>
                    {
                      item.PrimaryServices &&
                      <Content>
                        <Submenu services={item.PrimaryServices} />
                      </Content>
                    }
                  </Item>
                ))}
              </NavigationMenu.List>
              <NavigationMenu.Indicator className='NavigationMenuIndicator'>
                <div className='Arrow' />
              </NavigationMenu.Indicator>
              <div className='ViewportPosition'>
                <NavigationMenu.Viewport className='NavigationMenuViewport' />
              </div>
            </NavigationMenu.Root>
          ),
        )
      }
    </>
  );
}

type ChildProp = {
  children: ReactNode
}

const Item = ({ children }: ChildProp) => {
  return (
    <NavigationMenu.Item>
      {children}
    </NavigationMenu.Item>
  );
};

const Trigger = ({ children }: ChildProp) => {
  return (
    <NavigationMenu.Trigger className='NavigationMenuTrigger'>
      {children}
      <CaretDownIcon className='CaretDown' aria-hidden />
    </NavigationMenu.Trigger>
  );
};

const Content = ({ children }: ChildProp) => {
  return (
    <NavigationMenu.Content className='NavigationMenuContent'>
      {children}
    </NavigationMenu.Content>
  );
};