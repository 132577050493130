'use client';

import React, { useState, useEffect, CSSProperties } from 'react';
import tw from 'tailwind-styled-components';
import { Button } from '@/components/Button';
import Image from 'next/image';
import { Category, PrimaryServices, SecondaryServices } from '@/types';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon, ArrowRightIcon } from '@radix-ui/react-icons';
import { motion, Variants } from 'framer-motion';


const MenuContext = tw.div`
h-screen
w-[100%]
absolute
right-0
top-0
bg-[#00000069]
z-[99]
`;

const icon: CSSProperties = {
  fontSize: 23,
};

interface Props {
  categories: Array<Category>;
}

const openContextMenu: Variants = {
  hide: {
    x: 300,
  },
  show: {
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const logoStyle = {
  width: 110,
  height: 21
}

export function Menu({ categories }: Props) {
  const [isOpen, handleOpen] = useState<boolean>(false);

  const openMenu = () => {
    handleOpen(!isOpen);
  };

  const setHtmlOverflow = (value: string) => {
    document.documentElement.style.overflow = value;
  };

  useEffect(() => {
    if (isOpen) {
      setHtmlOverflow('hidden');
      return;
    }

    return setHtmlOverflow('auto');

  }, [isOpen]);

  return (
    <>
      <Button onClick={openMenu}>
        <i style={icon} className={'mdi mdi-menu'} />
      </Button>

      {
        isOpen &&
        <MenuContext>
          <motion.div
            className='h-full w-full '
            initial='hide'
            whileInView='show'
            viewport={{ once: true }}
            variants={openContextMenu}
          >
            <div className={'h-full w-full bg-[white] overflow-auto'}>
              <div
                className={'border-bottom-mobile fixed w-full bg-[white] z-50 px-5 pb-5 pt-10 flex flex-col items-start justify-center'}>
                <Image
                  width="0"
                  height="0"
                  src={'/logo.webp'}
                  alt={'Logo'}
                  priority={false}
                  placeholder='empty'
                  style={logoStyle}
                />
                <Button
                  onClick={openMenu}
                  className='flex justify-center items-center transition bg-primary mt-5 rounded-md w-[117px] h-[36px]'
                  href='/#cadastre-se'
                >
                  <span className='text-[white] text-[14px]'>Cadastre-se</span>
                </Button>
                <Button onClick={openMenu} className={'absolute right-2 top-1'}>
                  <i className='text-[25px] mdi mdi-close text-[#545454]' />
                </Button>
              </div>

              <div className={'p-5 mt-[150px]'}>
                <small className={'text-[#BFBFBF]'}>Categorias</small>
                <div className={'pt-3'}>
                  <Accordion.Root type='multiple'>
                    {
                      categories &&
                      categories.map((item: Category, i: number) =>
                        <Accordion.Item key={i} value={`item-${item.Name}`}>
                          <Accordion.Header>
                            <Accordion.Trigger
                              disabled={!item.PrimaryServices}
                              className={'w-full group flex h-[35px] items-center justify-between bg-white outline-none'}>
                              <p className={'text-[#545454]'} key={i}>{item.Name}</p>
                              {
                                item.PrimaryServices &&
                                <ChevronDownIcon
                                  className='text-violet10 ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180'
                                  aria-hidden
                                />
                              }

                            </Accordion.Trigger>
                          </Accordion.Header>

                          <Accordion.Content
                            className={'data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden'}>
                            <ul className={'pl-5'}>
                              {item.PrimaryServices &&
                                item.PrimaryServices.map((service: PrimaryServices, j) =>
                                  <li key={j}
                                      className={'my-1 w-full'}>
                                    <Button
                                      className={'flex items-center text-[14px]'}
                                      href={`/${service.SlugName}`}>
                                      <ArrowRightIcon color={'#BFBFBF'}
                                                      className={'ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-90'} />
                                      <span
                                        className={'pl-3'}>{service.Name}</span>
                                    </Button>
                                  </li>,
                                )
                              }
                            </ul>
                          </Accordion.Content>
                        </Accordion.Item>,
                      )
                    }
                  </Accordion.Root>
                </div>
              </div>

              <div className={'px-5'}>
                <hr className={'text-[#EFEFEF]'} />
              </div>

              <div className={'relative px-5 pb-5 pt-5 flex flex-col items-start justify-center'}>
                <Button onClick={openMenu} className={'mb-3'} href={'#como-funciona'}>
                  Como funciona o Orce Fácil
                </Button>
                <Button onClick={openMenu} href={'/termos-e-politicas'}>
                  Termos e políticas
                </Button>
              </div>
            </div>
          </motion.div>
        </MenuContext>
      }
    </>
  );
}