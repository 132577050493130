import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/src/app/(site)/globals.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/(site)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/src/app/(site)/providers.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/CookiePopup.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Forms/Search.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Layout/Container/styles.scss");
import(/* webpackMode: "eager" */ "/app/src/components/Logo.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Menu.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Navigation/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Scripts/Adsense.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Scripts/Analytics.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Scripts/GtagEvent.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Scripts/Hotjar.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/SearchMobile.tsx")