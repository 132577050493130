'use client';

import { useState, ChangeEvent, FormEvent, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { Input } from '@/components/Forms/Input';
import { Button } from '@/components/Button';
import { Service } from '@/types';
import { Autocomplete } from '@/components/Forms/Autocomplete';
import * as Popover from '@radix-ui/react-popover';
import { usePathname, useRouter } from 'next/navigation';
import React, { HTMLProps, CSSProperties } from 'react';
import NotFound from '@/components/Forms/NotFound';

const content = 'z-[99] text-sm md:text-base mt-2 outline-0 rounded-md bg-[white] shadow-[0px_0px_7px_0px_hsla(206,22%,7%,.25),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade';

interface SearchProps extends HTMLProps<HTMLInputElement> {
  scale?: number;
  marginTop?: number;
  isHeader?: boolean;
  widthForm?: number;
  width?: number;
  justifyContent?: string;
}

export function Search({ scale, marginTop, isHeader, widthForm, justifyContent }: SearchProps) {
  const router = useRouter();
  const pathName = usePathname();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [segmento, setSegmento] = useState<string>('');
  const [services, setServices] = useState<Array<Service> | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [navigator, setNavigator] = useState<string | null>(null);

  const [displayHome, setDisplayHome] = useState<string>(
    isHeader && pathName == '/' ? 'none' : 'flex',
  );

  const handleInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setSegmento(value || '');
    },
    [],
  );

  const handleServices = async () => {
    const params = new URLSearchParams({
      segmento: segmento,
    });

    const response = await fetch(`/api/search?${params}`).then((response) =>
      response.json(),
    );

    if (response?.data?.ArrayServiceSearch) {
      setServices(response.data.ArrayServiceSearch);
      setIsOpen(true);
      setNotFound(false);
    } else {
      setServices([]);
      setNotFound(true);
    }
  };

  useEffect(() => {
    segmento.length >= 3 ? handleServices() : setServices(undefined);
  }, [segmento]);

  useLayoutEffect(() => {
    setNotFound(false);
    if (isHeader) {
      if (pathName != '/')
        setDisplayHome('flex');
      else
        setDisplayHome('none');
    }
  }, [isHeader, pathName]);

  const handleSearch = async (e: FormEvent) => {
    e.preventDefault();
    // @ts-ignore
    router.push(`/${navigator}`);

  };

  const handlefocus = (e: any): void => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleClick = (navigator: { term: string, Url: string }) => {
    const { term, Url } = navigator;
    setSegmento(term);
    setNavigator(Url);
    setIsOpen(false);
  };

  const headerSearchStyle: CSSProperties = {
    transform: `scale(${scale})`,
    marginTop: `${marginTop}px`,
    display: `${displayHome}`,
    width: `100%`,

  };

  const headerFormStyle: CSSProperties = {
    width: `${widthForm}%`,
    display: `${displayHome}`,
    justifyContent: `${justifyContent}`,
  };

  return (
    <>
      <Popover.Root open={isOpen}>
        <Popover.Trigger />
        <Popover.Anchor asChild>
          <form onSubmit={handleSearch}
                style={headerFormStyle}
          >
            <div
              style={headerSearchStyle}
              className={'rounded-full p-2 styled-search-component md:mt-3 md:w-[738px] w-95 md:h-[60px] h-[67px] flex items-center justify-between'}>
              <Input
                className={'md:w-[450px] w-[100%] text-sm md:text-lg pl-2 md:pl-7 placeholder:text-[#818181]'}
                autoComplete={'off'}
                ref={inputRef}
                id={'search'}
                type={'text'}
                placeholder={'Qual serviço você precisa?'}
                onInput={handleInputChange}
                value={segmento}
              />
              <Button
                type={'submit'}
                className={
                  'transition items-center min-w-[83px] w-[115px] h-[44px] bg-primary hover:border-2 hover:border-[#3d66dc] rounded-full text-[white] hover:text-bold hover:text-primary responsive-buscar-text'
                }
              >
                <i className={'mdi mdi-magnify'} /> Buscar
              </Button>
            </div>
          </form>
        </Popover.Anchor>

        <Popover.Portal>
          <Popover.Content onInteractOutside={() => setIsOpen(false)} className={content}
                           onOpenAutoFocus={handlefocus}>
            {services &&
              <Autocomplete callback={handleClick} services={services} />
            }
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      <NotFound notFound={notFound}></NotFound>
    </>
  );
}
