'use client';

import React, { useState, CSSProperties } from 'react';
import { Button } from '@/components/Button';
import { Search } from '@/components/Forms/Search';
import { motion, Variants } from 'framer-motion';


const rightContentShowMobile: Variants = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
};

const icon: CSSProperties = {
  fontSize: 23,
};

export function SearchMobile() {
  const [isOpen, handleOpen] = useState<boolean>(false);

  const openSearch = () => {
    handleOpen(!isOpen);
  };

  return (
    <>
      <Button onClick={openSearch}>
        <i style={icon} className='mdi mdi-magnify' />
      </Button>

      {
        isOpen &&
        <motion.div className='w-full
                            absolute
                            top-[60px]
                            left-0
                            bg-[white]
                            z-[98]
                            px-4
                            pb-5
                            border-bottom-mobile'
                    initial='hide'
                    whileInView='show'
                    variants={rightContentShowMobile}
        >
          <Search widthForm={100} />
        </motion.div>
      }
    </>
  );
}